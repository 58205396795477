<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="main.intro.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <!--                <va-input-->
                <!--                  label="Subheader"-->
                <!--                  v-model="main.intro.subheader"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <!--                <va-input-->
                <!--                  label="Desc"-->
                <!--                  v-model="main.intro.desc"-->
                <!--                  :messages="['The recommended number of characters is 15']"-->
                <!--                />-->
                <va-card class="mb-3" title="Image Main *">
                  <div v-if="imgIntroExisting">
                    <a style="display: block;" target="_blank" :href="imgIntroExisting"><img style="max-width: 400px;" :src="imgIntroExisting"></a>
                    <div><va-button small color="danger" @click="imgIntroExisting = deleteImage(imgId) ? '' : imgIntroExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>
              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Tag Header"
                  v-model="main.tag.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <br>
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutTag' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutTagItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutTag' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-input
                  label="Ceo Header"
                  v-model="main.ceo.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Ceo Subheader"
                  v-model="main.ceo.subheader"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Ceo Desc"
                  v-model="main.ceo.desc"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Ceo subheader link"
                  v-model="main.ceo.link"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-card class="mb-3" title="Image Ceo *">
                  <div v-if="imgCeoExisting">
                    <a style="display: block;" target="_blank" :href="imgCeoExisting"><img style="max-width: 400px;" :src="imgCeoExisting"></a>
                    <div><va-button small color="danger" @click="imgCeoExisting = deleteImage(imgId) ? '' : imgCeoExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="imgSeo"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage(imgSeo[0],'imgCeoExisting')">Завантажити</va-button>
                </va-card>
              </div>
              <div class="flex xs12" v-if="tabValue === 3">
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutValue' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutValueItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutValue' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 4">
                <va-input
                  label="Basis Header"
                  v-model="main.basis.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <br>
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutBasis' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutBasisItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutBasis' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 5">
                <va-input
                  label="Basis 2 Header"
                  v-model="main.basis2.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <br>

                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutBasis2' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutBasis2Items"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutBasis2' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 6">
                <va-input
                  label="Enterprise Header"
                  v-model="main.enterprise.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Enterprise subtitle"
                  v-model="main.enterprise.subtitle"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutEnterprise' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutEnterpriseItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutEnterprise' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 7">
                <va-input
                  label="Honest Header"
                  v-model="main.honest.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Intro *</span>
                  <ckeditor v-model="main.honest.text" :editor="editor" :config="editorConfig"></ckeditor>
                </div>

                <br>
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutHonest' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutHonestItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutHonest' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 8">
                <va-input
                  label="Prinsip Header"
                  v-model="main.prinsip.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <br>
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutPrinsip' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutPrinsipItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutPrinsip' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
                <br>
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutPrinsip2' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutPrinsip2Items"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutPrinsip2' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <div class="flex xs12" v-if="tabValue === 9">
                <va-input
                  label="About Header"
                  v-model="main.about.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="About title"
                  v-model="main.about.title"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="About subtitle"
                  v-model="main.about.subtitle"
                  :messages="['The recommended number of characters is 15']"
                />

                <br>
                <va-button :to="{ name: 'ms-counter', params: { field: 'aboutPro' } }">
                  Додати
                </va-button>
                <va-data-table
                  :fields="tagTabFields"
                  :data="aboutProItems"
                  no-pagination
                >
                  <template slot="marker" slot-scope="props">
                    <va-icon name="fa fa-circle" :color="props.rowData.color" size="8px" />
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'ms-counter', params: { id: props.rowData.id, field: 'aboutPro' } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="removeCounter(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      main: {
        about: {
          header: null,
          title: null,
          subtitle: null,
        },
        tag: {
          header: null,
        },
        basis: {
          header: null,
        },
        basis2: {
          header: null,
        },
        enterprise: {
          header: null,
          subtitle: null,
        },
        honest: {
          header: null,
          text: null,
        },
        prinsip: {
          header: null,
        },
        ceo: {
          desc: null,
          header: null,
          img: null,
          link_href: null,
          link_name: null,
          subheader: null,
        },
        intro: {
          desc: null,
          header: null,
          img: null,
          link_href: null,
          link_name: null,
          subheader: null,
        },
        mission: {},
        principles: {},
        values: {},
      },
      history: null,
      missions: null,
      principles: null,

      aboutTagItems: [],
      aboutValueItems: [],
      aboutBasisItems: [],
      aboutBasis2Items: [],
      aboutEnterpriseItems: [],
      aboutHonestItems: [],
      aboutPrinsipItems: [],
      aboutPrinsip2Items: [],
      aboutProItems: [],

      imgIntroExisting: null,
      imgCeoExisting: null,
      imgMissionExisting: null,
      imgPrinciplesExisting: null,
      imgValuesExisting: null,

      imgIntroNew: null,
      imgCeoNew: null,
      imgMissionNew: null,
      imgPrinciplesNew: null,
      imgValuesNew: null,

      header: '',
      subheader: '',
      desc: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      quotes: null,
      stats: null,
      companyContents: null,

      img: [],
      imgSeo: [],
      imgId: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_MS_DEV_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main', 'Tag', 'Ceo', 'Value', 'Basis', 'Basis 2', 'Enterprise', 'Honest', 'Prinsip', 'About'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async removeCounter (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/counter/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
    },
    uploadImage (file, tab = 'imgIntro') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      console.log(file, tab)
      axios.post(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          if (tab === 'imgIntro') {
            this.imgIntroNew = response.data.patch
          }
          if (tab === 'imgCeoExisting') {
            this.imgCeoNew = response.data.patch
          }
          if (tab === 'imgMissionExisting') {
            this.imgMissionNew = response.data.patch
          }
          if (tab === 'imgPrinciplesExisting') {
            this.imgPrinciplesNew = response.data.patch
          }
          if (tab === 'imgValuesExisting') {
            this.imgValuesNew = response.data.patch
          }
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      this.main.intro.img = this.imgIntroNew
      this.main.ceo.img = this.imgCeoNew
      this.main.mission.img = this.imgMissionNew
      this.main.principles.img = this.imgPrinciplesNew
      this.main.values.img = this.imgValuesNew
      const data = {
        main: this.main,
      }
      axios.put(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/about`, data)
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_MS_DEV_API_URL}/${this.locale}/admin/about`)
        .then(response => {
          this.main = response.data.main
          this.history = response.data.history
          this.missions = response.data.missions
          this.principles = response.data.principles
          this.imgIntroNew = response.data.main.intro.img
          this.imgCeoNew = response.data.main.ceo.img
          this.imgMissionNew = response.data.main.mission.img
          this.imgPrinciplesNew = response.data.main.principles.img
          this.imgValuesNew = response.data.main.values.img

          this.aboutTagItems = response.data.aboutTagItems
          this.aboutValueItems = response.data.aboutValueItems
          this.aboutBasisItems = response.data.aboutBasisItems
          this.aboutBasis2Items = response.data.aboutBasis2Items
          this.aboutEnterpriseItems = response.data.aboutEnterpriseItems
          this.aboutHonestItems = response.data.aboutHonestItems
          this.aboutPrinsipItems = response.data.aboutPrinsipItems
          this.aboutPrinsip2Items = response.data.aboutPrinsip2Items
          this.aboutProItems = response.data.aboutProItems

          this.imgIntroExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.main.intro.img
          this.imgCeoExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.main.ceo.img
          this.imgMissionExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.main.mission.img
          this.imgPrinciplesExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.main.principles.img
          this.imgValuesExisting = `${process.env.VUE_APP_MS_DEV_URL}/` + response.data.main.values.img
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    tagTabFields () {
      return [
        {
          name: 'id',
          title: 'Key',
          width: '10%',
        },
        {
          name: 'label',
          title: 'Label',
          width: '40%',
        },
        {
          name: 'description',
          title: 'Description',
          width: '40%',
        },
        {
          title: 'Переклади',
          name: '__slot:translations',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
